//@flow
import qs from 'query-string';
import { object, array, string, boolean, mixed, type YupObject } from 'yup';
import { type MobileAppPlatformEnum } from '@dt/enums/MobileAppPlatformEnum';
import TemplateScanType, {
  type TemplateScanTypeEnum,
} from '@dt/enums/TemplateScanTypeEnum';
import type { ReleaseType as MobileAppReleaseEnumType } from '@dt/enums/MobileAppReleaseTypeEnum';
import { type Target } from '@dt/findings/types';
import { type AppScanBlockedReasonEnum } from '@dt/enums/AppScanBlockedReasonEnum';
import TaskStatus, { type TaskStatusEnum } from '@dt/enums/TaskStatusEnum';
import { type SecurityTemplateSeverityEnum as SecurityTemplateSeverityEnumType } from '@dt/enums/SecurityTemplateSeverityEnum';
import { TargetStatusSchema } from './_targets';
import config from '@dt/config';
import fetch, { parse } from '@dt/fetch';
import { byProperty, result } from './util';
import type { CompliancePolicyReference as CompliancePolicyReferenceType } from '@dt/findings/types';
import type { PaginatedResponse } from './_common';
import { type AppProtectionMetrics } from './app_protection_metrics';

const sevenhellApiVersion = '/_ah/api/userapi/v2';

export type MetadataCount = {
  P1: number,
  HIGH: number,
  MEDIUM: number,
  LOW: number,
  APPLE_BLOCKER: number,
  GOOGLE_BLOCKER: number,
  COMPLIANCE: number,
  SDK: number,
  INSIGHT: number,
  ALL: number,
  ...
};

export type ShadowMetadataCountType = {
  P1: number,
  HIGH: number,
  MEDIUM: number,
  LOW: number,
  APPLE_BLOCKER: number,
  GOOGLE_BLOCKER: number,
  COMPLIANCE: number,
  INSIGHT: number,
  ALL: number,
  ...
};

export type CategoryMetadataCount = {
  OPEN: ?MetadataCount,
  CLOSED: ?MetadataCount,
  SHADOW: ?ShadowMetadataCountType,
  ...
};

export type ApplicationMetadata = {
  closed_security_finding_apple_app_store_blocker_beyond_subscription_level_count: string,
  closed_security_finding_apple_app_store_blocker_count: string,
  closed_security_finding_beyond_subscription_level_count: string,
  closed_security_finding_count: string,
  closed_security_finding_google_play_blocker_beyond_subscription_level_count: string,
  closed_security_finding_google_play_blocker_count: string,
  closed_security_finding_high_beyond_subscription_level_count: string,
  closed_security_finding_high_count: string,
  closed_security_finding_low_beyond_subscription_level_count: string,
  closed_security_finding_low_count: string,
  closed_security_finding_medium_beyond_subscription_level_count: string,
  closed_security_finding_medium_count: string,
  closed_security_finding_priority_alerts_beyond_subscription_level_count: string,
  closed_security_finding_priority_alerts_count: string,
  closed_security_finding_security_p1_beyond_subscription_level_count: string,
  closed_security_finding_security_p1_count: string,
  closed_security_finding_regulatory_compliance_count: string,
  closed_security_finding_regulatory_compliance_beyond_subscription_level_count: string,
  open_insight_finding_count: string,
  open_sdk_finding_beyond_subscription_level_count: string,
  open_sdk_finding_beyond_subscription_level_with_open_issues_beyond_subscription_level_count: string,
  open_sdk_finding_count: string,
  open_sdk_finding_with_open_issues_beyond_subscription_level_count: string,
  open_sdk_finding_with_open_issues_count: string,
  open_security_finding_apple_app_store_blocker_beyond_subscription_level_count: string,
  open_security_finding_apple_app_store_blocker_count: string,
  open_security_finding_beyond_subscription_level_count: string,
  open_security_finding_count: string,
  open_security_finding_google_play_blocker_beyond_subscription_level_count: string,
  open_security_finding_google_play_blocker_count: string,
  open_security_finding_high_beyond_subscription_level_count: string,
  open_security_finding_high_count: string,
  open_security_finding_low_beyond_subscription_level_count: string,
  open_security_finding_low_count: string,
  open_security_finding_medium_beyond_subscription_level_count: string,
  open_security_finding_medium_count: string,
  open_security_finding_priority_alerts_beyond_subscription_level_count: string,
  open_security_finding_priority_alerts_count: string,
  open_security_finding_security_p1_beyond_subscription_level_count: string,
  open_security_finding_security_p1_count: string,
  open_security_finding_regulatory_compliance_count: string,
  open_security_finding_regulatory_compliance_beyond_subscription_level_count: string,
  ...
};

export type ApplicationScan = {
  end_date?: string,
  date_updated: string,
  mobile_app_id: string,
  start_date: string,
  date_created: string,
  app_version?: string,
  id: string,
  ...
};

export type Integrations = {
  ci_cd: ?boolean,
  issue_tracker: ?boolean,
  trust_kit: ?boolean,
  ...
};

export type Application = {
  app_protection_score?: string,
  app_protection_score_ratio?: number,
  blocked_scan_reason?: AppScanBlockedReasonEnum,
  bundle_id?: string,
  category?: string,
  date_created?: string,
  date_updated?: string,
  icon_url?: string,
  id: string,
  integrations?: Integrations,
  max_app_protection_score?: string,
  metadata?: ApplicationMetadata,
  most_recent_scan?: ApplicationScan,
  name: string,
  platform: MobileAppPlatformEnum,
  release_type?: MobileAppReleaseEnumType,
  store_url?: string,
  subscription: TemplateScanTypeEnum,
  is_enterprise_internal: boolean,
  app_store_customer_mobile_app_id?: string,
  trustkit_url?: string,
  ...AppProtectionMetrics,
};

export type Applications = $ReadOnlyArray<Application>;

export type ApplicationWithMetadataCount = {
  ...Application,
  metadataCount: CategoryMetadataCount,
};

export type ApplicationsWithMetadataCount = $ReadOnlyArray<ApplicationWithMetadataCount>;

export type ListResponse = PaginatedResponse<{
  mobile_apps: Applications,
  ...
}>;

const IntegrationsSchema = object<Integrations>().shape({
  ci_cd: boolean(),
  issue_tracker: boolean(),
  trust_kit: boolean(),
});

export const ApplicationSchema: YupObject<Application> = object().shape({
  id: string().required(),
  platform: string().required(),
  name: string().required(),
  subscription: mixed()
    .label('Subscription')
    .oneOf(Object.keys(TemplateScanType))
    .required(),
  integrations: IntegrationsSchema,
  max_app_protection_score: string(),
});

async function validate(result): Promise<Application> {
  return ApplicationSchema.validate(result);
}

async function validateList(result): Promise<ListResponse> {
  return object()
    .shape({
      mobile_apps: array().of(ApplicationSchema),
    })
    .validate(result);
}

export async function get(id: string): Promise<Application> {
  if (!id || !id.length) {
    throw new Error('Invalid application ID provided');
  }

  return fetch(`${config.sevenhellAPI}${sevenhellApiVersion}/mobile_apps/${id}`)
    .then(parse)
    .then(result)
    .then(validate);
}
export type ListParams = {
  +cursor?: ?string,
  +platform?: ?string,
  +subscription?: ?string,
  +updated_since?: ?string,
  +name?: ?string,
  ...
};

export async function list(params?: ?ListParams): Promise<ListResponse> {
  return fetch(
    `${config.sevenhellAPI}${sevenhellApiVersion}/mobile_apps${
      params && qs.stringify(params) ? `?${qs.stringify(params)}` : ''
    }`,
  )
    .then(parse)
    .then(result)
    .then(validateList);
}

export type TrustKitReport = {
  //"test_env / misconfiguration / certificate_chain_valid",
  attack_category?: string,
  certificates?: $ReadOnlyArray<Certificate>,
  city?: string,
  country?: string,
  //"2017-08-25T12:25:26",
  date_received?: string,
  //"https://overmind.datatheorem.com/trustkit/report/download/abc/123",
  download_link?: string,
  //"www.yahoo.com",
  hostname?: string,
  id?: string,
  mobile_app_id?: string,
  received_certificates_common_names?: [
    '*.www.yahoo.com',
    'DigiCert SHA2 High Assurance Server CA',
    'DigiCert High Assurance EV Root CA',
  ],
  //"ha"
  region?: string,
  //"1"
  validation_result?: string,
  //"12345ABC-9876-AB12-12AB-ABCDEF1234"
  vendor_id?: string,
  ...
};

export type Certificate = {
  common_names?: $ReadOnlyArray<string>,
  subject?: string,
  subject_alternative_names?: $ReadOnlyArray<string>,
  ...
};

export type ListTrustKitReportsParams = { attack_category?: string, ... };

export type TrustKitAnalysis = {
  last_ten_reports: $ReadOnlyArray<TrustKitReport>,
  mobile_app_id: string,
  //"https://overmind.datatheorem.com/trustkit/report/download/abc123",
  reports_download_link?: string,
  stats_countries?: $ReadOnlyArray<KeyValuePair>,
  stats_last_six_months?: $ReadOnlyArray<MonthStats>,
  top_attack_categories?: $ReadOnlyArray<KeyValuePair>,
  top_five_ca_certificates?: $ReadOnlyArray<KeyValuePair>,
  top_five_countries?: $ReadOnlyArray<KeyValuePair>,
  top_five_domains?: $ReadOnlyArray<KeyValuePair>,
  // number
  total_app_installs?: string,
  // number
  total_attacks_blocked?: string,
  // number
  total_pinning_failures?: string,
  ...
};

export type KeyValuePair = {
  key?: string,
  // number
  value?: string,
  ...
};

export type MonthStats = {
  // number
  app_installs?: string,
  // number
  attacks_blocked?: string,
  //"2017-08",
  date?: string,
  // number
  reports_received?: string,
  ...
};

export const trustkit = {
  reports: {
    async list(
      mobile_app_id: string,
      params?: ListTrustKitReportsParams,
    ): Promise<$ReadOnlyArray<TrustKitReport>> {
      if (!mobile_app_id) {
        throw new Error('Invalid application id provided');
      }

      return fetch(
        `${
          config.sevenhellAPI
        }${sevenhellApiVersion}/mobile_apps/${mobile_app_id}/trustkit/reports${
          params && qs.stringify(params) ? `?${qs.stringify(params)}` : ''
        }`,
      )
        .then(parse)
        .then(result)
        .then(byProperty('reports'));
    },
  },
  analysis: {
    async get(mobile_app_id: string): Promise<TrustKitAnalysis> {
      if (!mobile_app_id) {
        throw new Error('Invalid application id provided');
      }

      return fetch(
        `${config.sevenhellAPI}${sevenhellApiVersion}/mobile_apps/${mobile_app_id}/trustkit/analysis`,
      )
        .then(parse)
        .then(result);
    },
  },
};

export type AppProtectionTask = {
  //number
  app_protection_score: string,
  description: string,
  description_intro?: string,
  mobile_app_id: string,
  recommendation?: string,
  secure_code?: string,
  security_finding_id?: string,
  targets?: $ReadOnlyArray<Target>,
  task_status: TaskStatusEnum,
  title: string,
  is_beyond_subscription_level?: boolean,
  issue_type_id: string,
  severity: SecurityTemplateSeverityEnumType,
  compliance_policy_references: $ReadOnlyArray<CompliancePolicyReferenceType>,
  ...
};

export type AppProtectionTasksMetadata = {
  mobile_app_id: string,
  // number
  completed_points: string,
  // number
  completed_tasks_count: string,
  // number
  not_completed_points: string,
  // number
  not_completed_tasks_count: string,
  // number
  unknown_points: string,
  // number
  unknown_tasks_count: string,
  // number
  requires_enterprise_scan_points: string,
  // number
  requires_enterprise_scan_tasks_count: string,
  ...
};

type AppProtectionTasksResponse = {
  app_protection_tasks: $ReadOnlyArray<AppProtectionTask>,
  app_protection_tasks_metadata: AppProtectionTasksMetadata,
  ...
};

const AppProtectionTaskTargetSchema = object().shape({
  id: string().required(),
  statuses: array()
    .of(TargetStatusSchema)
    .required(),
});

export const AppProtectionTaskSchema: YupObject<AppProtectionTask> = object().shape(
  {
    app_protection_score: string().required(),
    description: string().required(),
    description_intro: string(),
    mobile_app_id: string().required(),
    recommendation: string(),
    secure_code: string(),
    security_finding_id: string(),
    targets: array().of(AppProtectionTaskTargetSchema),
    task_status: mixed()
      .label('taskStatus')
      .oneOf(Object.values(TaskStatus))
      .required(),
    title: string().required(),
  },
);

export const AppProtectionTasksMetadataSchema: YupObject<AppProtectionTasksMetadata> = object().shape(
  {
    mobile_app_id: string().required(),
    completed_points: string().required(),
    completed_tasks_count: string().required(),
    not_completed_points: string().required(),
    not_completed_tasks_count: string().required(),
    unknown_points: string().required(),
    unknown_tasks_count: string().required(),
    requires_enterprise_scan_points: string().required(),
    requires_enterprise_scan_tasks_count: string().required(),
  },
);

async function validateTaskList(result): Promise<AppProtectionTasksResponse> {
  return object()
    .shape({
      app_protection_tasks: array().of(AppProtectionTaskSchema),
      app_protection_tasks_metadata: AppProtectionTasksMetadataSchema,
    })
    .validate(result);
}

export const app_protection_tasks = {
  async list(mobile_app_id: string): Promise<AppProtectionTasksResponse> {
    if (!mobile_app_id) {
      throw new Error('Invalid application id provided');
    }

    return fetch(
      `${config.sevenhellAPI}${sevenhellApiVersion}/mobile_apps/${mobile_app_id}/app_protection_tasks`,
    )
      .then(parse)
      .then(result)
      .then(validateTaskList);
  },
};

type PatchParams = {
  is_enterprise_internal: ?boolean,
  linked_app_store_mobile_app_id: ?string,
  ...
};

export async function patch(
  id: string,
  params: PatchParams,
): Promise<Application> {
  if (!id || !id.length) {
    throw new Error('An invalid mobile app id was provided.');
  }

  if (!params) {
    throw new Error(
      'Either is_enterprise_internal or linked_app_store_mobile_app_id should be provided.',
    );
  }

  // Nullify undefined values for Sevenhell to handle them correctly
  if (
    typeof params.is_enterprise_internal === 'undefined' ||
    params.is_enterprise_internal === null
  ) {
    delete params['is_enterprise_internal'];
  }

  if (
    typeof params.is_enterprise_internal !== 'undefined' &&
    params.is_enterprise_internal
  ) {
    params['linked_app_store_mobile_app_id'] = null;
  }

  return fetch(
    `${config.sevenhellAPI}${sevenhellApiVersion}/mobile_apps/${id}`,
    {
      method: 'PATCH',
      body: JSON.stringify(params),
    },
  )
    .then(parse)
    .then(result);
}
