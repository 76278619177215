//@flow
import React from 'react';
import { Card, CardContent, Grid } from '@material-ui/core';
import Text from './Text';
import { palette } from '@dt/theme';

type Props = {|
  number: number,
  title: string,
|};

export default React.memo<Props>(function TotalCard(props) {
  const { number, title } = props;

  return (
    <Card
      style={{ minHeight: '160px', maxHeight: '160px' }}
      aria-label={`${number} ${title}`}
    >
      <CardContent style={{ padding: '16px' }}>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          alignContent="center"
          spacing={2}
        >
          <Grid item>
            <Text variant="numberXL" color={palette.gray20}>
              {number}
            </Text>
          </Grid>

          <Grid item>
            <Text variant="body" color={palette.gray20}>
              {title}
            </Text>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
});
